import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select", "mainMap"];

  selectState() {
    let displayedMap = document.querySelectorAll(".law:not(.hidden)");

    if (displayedMap) {
      displayedMap.forEach((mapInfo) => {
        this.hide(mapInfo);
      });
    }

    if (this.selectTarget.value !== "") {
      this.hide(this.mainMapTarget);

      if (document.getElementById(`law_${this.selectTarget.value}`)) {
        this.show(document.getElementById(`law_${this.selectTarget.value}`));
        this.show(document.getElementById(`map_${this.selectTarget.value}`));
      }
    } else {
      this.show(this.mainMapTarget);
    }
  }

  hide(el) {
    el.classList.add("hidden");
  }

  show(el) {
    el.classList.remove("hidden");
  }
}
