import { Controller } from "@hotwired/stimulus";

const prepQuestion = "prep_q";
const yesQuestions = ["0", "2", "3", "4", "5", "6", "8"];

export default class extends Controller {
  static targets = ["continueStatus", "stopStatus"];

  set correctCount(count) {
    this._correctCount = count;
    this.hideStatuses();

    if (this.checkedCount == 0) {
      return;
    } else if (count == 9) {
      this.showStopUsage();
    } else {
      this.showContinueUsage();
    }
  }

  get correctCount() {
    return this._correctCount;
  }

  get statusElement() {
    return this.element.querySelector("#status");
  }

  connect() {
    this.checkedCount = 0;
    this.correctCount = 2;
    this.element.addEventListener("change", (event) => {
      this.handleClick(event);
    });
  }

  handleClick(event) {
    if (event.target.id == prepQuestion) {
      return;
    }

    if (event.target.checked) {
      this.checkedCount++;
      if (yesQuestions.includes(event.target.id)) {
        this.correctCount++;
      } else {
        this.correctCount--;
      }
    } else {
      this.checkedCount--;
      if (yesQuestions.includes(event.target.id)) {
        this.correctCount--;
      } else {
        this.correctCount++;
      }
    }
  }

  hideStatuses() {
    this.continueStatusTarget.style.display = "none";
    this.stopStatusTarget.style.display = "none";
  }

  showStopUsage() {
    this.stopStatusTarget.style.display = "inherit";
  }

  showContinueUsage() {
    this.continueStatusTarget.style.display = "inherit";
  }
}
