var relationshipQuizPage = document.getElementById("relationship-quiz");
if (relationshipQuizPage) {
  var TYPES = {
    1: "serious",
    2: "casual",
    3: "single-looking",
    4: "single-happy"
  };
  var participantAnswers = {};

  // show the first question
  document
    .getElementById("start-button")
    .addEventListener("click", function () {
      hide("#intro");
      show("#question-1");
    });

  document.querySelectorAll(".quiz-btn").forEach((button) => {
    button.addEventListener("click", function () {
      recordAnswer(button);
      var questionId = button.dataset.questionId;
      if (questionId == "5") {
        var type = calculateType();
        showResult("." + type);
      } else {
        showNextQuestion(questionId);
      }
    });
  });
}

function showResult(resultSelector) {
  hide("#question-5");
  show("#results");
  show(resultSelector);
}

function calculateType() {
  var type = Object.keys(participantAnswers).reduce(function (a, b) {
    return participantAnswers[a] > participantAnswers[b] ? a : b;
  });
  return type;
}

function showNextQuestion(currentQuestionId) {
  hide("#question-" + currentQuestionId);
  show("#question-" + (parseInt(currentQuestionId) + 1));
}

function recordAnswer(btn) {
  var selection = btn.dataset.type;
  var selectedType = TYPES[selection];
  participantAnswers[selectedType] =
    (participantAnswers[selectedType] || 0) + 1;
}

function hide(selector) {
  document.querySelectorAll(selector).forEach(function (element) {
    element.classList.add("hidden");
  });
}

function show(selector) {
  document.querySelectorAll(selector).forEach(function (element) {
    element.classList.remove("hidden");
  });
}
