import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static outlets = ["mdc--legacy-dialog"];

  openDialog(event) {
    if (this.mdcLegacyDialogOutlet) {
      this.mdcLegacyDialogOutlet.instantiate();
      this.mdcLegacyDialogOutlet.openDialog();
    }
  }
}
