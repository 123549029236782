var smartGoalSettingPage = document.getElementById("smart-goal-setting");
var smartGoalSelectionPage = document.getElementById("smart-goal-selection");
var smartGoalConfidenceReviewPage = document.getElementById(
  "goal-setting-activity-barrier-selection"
);

// initial goal setting page
if (smartGoalSettingPage) {
  if (smartGoalSettingPage.dataset.canModify == "true") {
    document.querySelectorAll(".answer").forEach((button) => {
      button.addEventListener("click", function (event) {
        event.preventDefault();
        removeOtherSelections(button.parentElement);
        button.firstElementChild.classList.add("selected");
        button.querySelector("input").checked = true;
      });
    });
  }

  function removeOtherSelections(section) {
    section.querySelectorAll(".answer").forEach((button) => {
      button.firstElementChild.classList.remove("selected");
    });
  }
}

// goal selection page
if (smartGoalSelectionPage) {
  if (smartGoalSelectionPage.dataset.canModify == "true") {
    var PERMITTED_SELECTIONS = 3;
    var $questions = document.querySelectorAll(".multiple-choice");

    document.querySelectorAll(".answer").forEach((button) => {
      button.addEventListener("click", function (event) {
        event.preventDefault();
        handleAnswerSelection(event.target, button);
      });
    });
  }

  function handleAnswerSelection(input, button) {
    if (button.firstElementChild.classList.contains("selected")) {
      button.firstElementChild.classList.remove("selected");
      button.querySelector("input").checked = false;
    } else {
      if (permittedGoalsSelected()) return;

      button.firstElementChild.classList.add("selected");
      button.querySelector("input").checked = true;
    }

    // if 3 options selected, show the submit section and disable unselected checkboxes
    if (permittedGoalsSelected()) {
      document.querySelector(".submit-text").classList.remove("display-none");
      document.querySelector(".submit-button").classList.remove("display-none");

      document
        .querySelectorAll(".multiple-choice input[type=checkbox]:not(:checked)")
        .forEach((checkbox) => {
          checkbox.disabled = true;
        });
    } else {
      // 3 options not selected, so make sure submit is hidden and checkboxes not disabled
      document.querySelector(".submit-text").classList.add("display-none");
      document.querySelector(".submit-button").classList.add("display-none");

      document
        .querySelectorAll(".multiple-choice input[type=checkbox]:not(:checked)")
        .forEach((checkbox) => {
          checkbox.disabled = false;
        });
    }
  }

  function permittedGoalsSelected() {
    return document.querySelectorAll(":checked").length == PERMITTED_SELECTIONS;
  }
}

// goal confidence review page
if (smartGoalConfidenceReviewPage) {
  if (smartGoalConfidenceReviewPage.dataset.canModify == "true") {
    document.querySelectorAll(".answer").forEach((button) => {
      button.addEventListener("click", function (event) {
        event.preventDefault();

        if (button.firstElementChild.classList.contains("selected")) {
          button.firstElementChild.classList.remove("selected");
          button.querySelector("input").checked = false;
        } else {
          button.firstElementChild.classList.add("selected");
          button.querySelector("input").checked = true;
        }
      });
    });
  }
}
