"use strict";

const firstProfileSeenKey = "firstProfile";
const secondProfileSeenKey = "secondProfile";
const thirdProfileSeenKey = "thirdProfile";

const simulator = document.getElementById("dating-app-simulator");

if (simulator && simulator.length != 0) {
  const screenNameKey = "screen-name";
  const lookingForKey = "looking-for";
  const hivStatusKey = "hiv-status";
  const profilePicKey = "profile-pic";

  if (!firstProfileSeen() || !secondProfileSeen() || !thirdProfileSeen()) {
    let profNextBtn = document.getElementById("profiles-homepage-next");
    profNextBtn ? (profNextBtn.style.display = "none") : null;
  }

  const prof1 = document.getElementById("profile-1-back");
  if (prof1) {
    prof1.addEventListener("click", () => {
      storeItem(firstProfileSeenKey, "true");
    });
  }

  const prof2 = document.getElementById("profile-2-back");
  if (prof2) {
    prof2.addEventListener("click", () => {
      storeItem(secondProfileSeenKey, "true");
    });
  }

  const prof3 = document.getElementById("profile-3-back");
  if (prof3) {
    prof3.addEventListener("click", () => {
      storeItem(thirdProfileSeenKey, "true");
    });
  }

  let playing = false;

  document.querySelector(".audio-icon").addEventListener("click", () => {
    if (playing) {
      document.querySelector(".narration").pause();
      playing = false;
    } else {
      document.querySelector(".narration").play();
      playing = true;
    }
  });

  setProfileReview();

  const profHomeBtn = document.querySelector(".profile-home-button");
  if (profHomeBtn) {
    profHomeBtn.style.display = "block";
  }

  const setScreenName = document.getElementById("set-screen-name");
  if (setScreenName) {
    setScreenName.addEventListener("click", () => {
      var screenName = document.querySelector(".screen-name-field").value;
      storeItem(screenNameKey, screenName);
    });
  }

  const setLookingFor = document.getElementById("set-looking-for");
  if (setLookingFor) {
    setLookingFor.addEventListener("click", () => {
      const selectedVal = capitalize(
        document.querySelector('input[name="looking[for]"]:checked').value
      );
      storeItem(lookingForKey, selectedVal);
    });
  }

  const setHivStatus = document.getElementById("set-hiv-status");
  if (setHivStatus) {
    setHivStatus.addEventListener("click", () => {
      const selectedVal = capitalize(
        document.querySelector('input[name="whats[your]"]:checked').value
      );
      storeItem(hivStatusKey, selectedVal);
    });
  }

  const profPicOption = document.querySelectorAll(".profile-pic-option");
  if (profPicOption) {
    profPicOption.forEach((pic) => {
      pic.addEventListener("click", () => {
        removeHilights(pic);
        document.querySelector(".next-button-invisible").style.display =
          "block";
        pic.classList.add("dating-app-hilighted");
        const id = pic.getAttribute("id");
        storeItem(profilePicKey, id);
      });
    });
  }

  const clickable = document.querySelectorAll(".clickable");
  if (clickable) {
    clickable.forEach((click) => {
      click.addEventListener("click", () => {
        const id = click.getAttribute("id");
        if (id.includes("not-risky")) {
          click.classList.add("not-risky");
        } else {
          click.classList.add("risky");
        }
      });
    });
  }

  function setProfileReview() {
    if (retrieveItem(profilePicKey)) {
      const profilePic = retrieveItem(profilePicKey);
      const src = simulator.getAttribute(`data-profile-${profilePic}`);
      const pic = document.getElementById("profile-review-picture");
      if (pic) {
        pic.setAttribute("src", src);
      }
    }

    if (retrieveItem(screenNameKey)) {
      const screenName = document.getElementById("screen-name");
      screenName ? (screenName.innerHTML = retrieveItem(screenNameKey)) : null;
    }

    if (retrieveItem(lookingForKey)) {
      const lookingFor = document.getElementById("looking-for");
      lookingFor ? (lookingFor.innerHTML = retrieveItem(lookingForKey)) : null;
    }

    if (retrieveItem(hivStatusKey)) {
      const hivStatus = document.getElementById("hiv-status");
      hivStatus ? (hivStatus.innerHTML = retrieveItem(hivStatusKey)) : null;
    }
  }

  function storeItem(key, value) {
    localStorage.setItem(key, value);
  }

  function retrieveItem(key) {
    return localStorage.getItem(key);
  }

  function removeHilights(element) {
    const profPicHilight = document.querySelectorAll(".profile-pic-option");
    profPicHilight.forEach((hilight) => {
      if (hilight !== element) {
        hilight.classList.remove("dating-app-hilighted");
      }
    });
  }

  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function firstProfileSeen() {
    return retrieveItem(firstProfileSeenKey) == "true";
  }

  function secondProfileSeen() {
    return retrieveItem(secondProfileSeenKey) == "true";
  }

  function thirdProfileSeen() {
    return retrieveItem(thirdProfileSeenKey) == "true";
  }
}
