var smartGoalSettingBoosterOnePage = document.getElementById(
  "smart-goal-setting-booster-one"
);

var smartGoalSettingBoosterOneBarriersPage = document.getElementById(
  "smart-goal-setting-booster-one-barriers"
);

if (smartGoalSettingBoosterOnePage) {
  var PERMITTED_SELECTIONS = 3;
  var checkboxes = document.querySelectorAll(".goal-checkbox");

  document.querySelectorAll(".goal-checkbox").forEach((checkbox) => {
    checkbox.addEventListener("click", function (event) {
      event.preventDefault();
      handleAnswerSelection(event.target, checkbox);
    });
  });

  function handleAnswerSelection(input, button) {
    if (button.classList.contains("selected")) {
      button.classList.remove("selected");
      button.querySelector("input").checked = false;
    } else {
      if (permittedGoalsSelected()) return;

      button.classList.add("selected");
      button.querySelector("input").checked = true;
    }

    // if 3 options selected, show the submit section and disable unselected checkboxes
    if (permittedGoalsSelected()) {
      document.querySelector(".submit-button").classList.remove("display-none");

      document
        .querySelectorAll(".goal-checkbox input[type=checkbox]:not(:checked)")
        .forEach((checkbox) => {
          checkbox.disabled = true;
        });
    } else {
      // 3 options not selected, so make sure submit is hidden and checkboxes not disabled
      document.querySelector(".submit-button").classList.add("display-none");

      document
        .querySelectorAll(".goal-checkbox input[type=checkbox]:not(:checked)")
        .forEach((checkbox) => {
          checkbox.disabled = false;
        });
    }
  }

  function permittedGoalsSelected() {
    return document.querySelectorAll(":checked").length == PERMITTED_SELECTIONS;
  }
}

if (smartGoalSettingBoosterOneBarriersPage) {
  document.querySelectorAll(".answer").forEach((button) => {
    button.addEventListener("click", function (event) {
      event.preventDefault();

      if (button.firstElementChild.classList.contains("selected")) {
        button.firstElementChild.classList.remove("selected");
        button.querySelector("input").checked = false;
      } else {
        button.firstElementChild.classList.add("selected");
        button.querySelector("input").checked = true;
      }
    });
  });
}
