import * as Sentry from "@sentry/browser";

let bodyData = document.getElementsByTagName("body")[0].dataset;

if (["staging", "production"].includes(bodyData["environment"])) {
  Sentry.init({
    dsn: bodyData["dsn"],
    environment: bodyData["environment"],
    release: "embedded_tools " + bodyData["release"],
    ignoreErrors: ["ResizeObserver loop limit exceeded"]
  });
}
