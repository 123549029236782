// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
require("@rails/activestorage").start();

import "@fortawesome/fontawesome-free/css/all.css";

import "./bootstrap-init.js";
import "./sentry-init.js";

require.context("../images", true);

import "./mixins/template_items.scss";
import "./mixins/template_variables.scss";

import "./material-theme.scss";
import "./shared.scss";

import "./humpr_tool/humpr_activity.js";
import "./humpr_tool/humpr_activity.scss";
import "./humpr_tool/dialog.scss";

import "./relationship_quiz_tool/relationship_quiz.js";
import "./relationship_quiz_tool/relationship_quiz.scss";

import "./serious_relationship_tool/serious_relationship.scss";

import "./smart_goal_setting/smart_goal_setting.js";
import "./smart_goal_setting/smart_goal_setting.scss";
import "./smart_goal_setting/boosters.js";
import "./smart_goal_setting/booster2.js";

import "./kiu_goal_setting_tool/kiu_goal_setting.scss";
import "./kiu_goal_setting_tool/booster.scss";

import "./sexual_health_laws_tool/sexual_health_laws.scss";

import "./condom_discontinuation_tool/condom_discontinuation.scss";

import "./condom_locator_tool/condom_locator.scss";

import "./prep_locator_tool/prep_locator.scss";

import "./sti_locator_tool/sti_locator.scss";

function importAll(r) {
  r.keys().forEach(r);
}
importAll(require.context("./hiv_risk_calculator", true, /.(js|scss)$/));
importAll(require.context("./sti_risk_calculator", true, /.(js|scss)$/));

import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

window.Stimulus = Application.start();

import {
  RaincoatMDCButtonController,
  RaincoatMDCDataTableController,
  RaincoatMDCFormFieldController,
  RaincoatMDCSelectController,
  RaincoatMDCTextFieldController,
  RaincoatMDCBannerController,
  RaincoatMDCDialogController
} from "raincoat";
Stimulus.register("mdc--button", RaincoatMDCButtonController);
Stimulus.register("mdc--data-table", RaincoatMDCDataTableController);
Stimulus.register("mdc--form-field", RaincoatMDCFormFieldController);
Stimulus.register("mdc--select", RaincoatMDCSelectController);
Stimulus.register("mdc--text-field", RaincoatMDCTextFieldController);
Stimulus.register("mdc--banner", RaincoatMDCBannerController);
Stimulus.register("mdc--dialog", RaincoatMDCDialogController);

const context = require.context("./controllers", true, /\.js$/);
Stimulus.load(definitionsFromContext(context));
Rails.start();
