import { Controller } from "@hotwired/stimulus";
import { MDCDialog } from "@material/dialog";

export default class extends Controller {
  connect() {
    this.element.addEventListener("open", (event) => {
      this.open(event.detail);
    });
    this.element.addEventListener("close", () => {
      this.close();
    });
  }

  disconnect() {
    this.destroyDialog();
  }

  open(options) {
    if (options.dialogUrl) {
      this.load(options.dialogUrl);
    } else if (options.dialogTemplateSelector) {
      this.local(options.dialogTemplateSelector);
    }
  }

  close() {
    if (this.dialog) {
      this.dialog.close();
    }
  }

  load(url) {
    fetch(url)
      .then((response) => response.text())
      .then((html) => (this.element.innerHTML = html))
      .then(() => {
        this.instantiate();
        this.openDialog();
      });
  }

  local(selector) {
    this.element.innerHTML = document.querySelector(selector).innerHTML;
    this.instantiate();
    this.openDialog();
  }

  instantiate() {
    this.dialog = new MDCDialog(this.element);
    this.addAccessibilityListeners();
    this.addClosedListener();
  }

  openDialog() {
    this.dialog.open();
  }

  addAccessibilityListeners() {
    this.dialog.listen("MDCDialog:opened", () => {
      document.querySelector("body").setAttribute("aria-hidden", "true");
    });
    this.dialog.listen("MDCDialog:closing", () => {
      document.querySelector("body").removeAttribute("aria-hidden");
    });
  }

  addClosedListener() {
    this.dialog.listen("MDCDialog:closed", () => {
      this.destroyDialog();
    });
  }

  destroyDialog() {
    if (this.dialog) {
      this.dialog.destroy();
      this.dialog = null;
    }
  }
}
