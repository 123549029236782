import { Toast } from "bootstrap";

let toastElement = document.getElementById("toast");

if (toastElement) {
  toastElement.addEventListener("triggerToast", (e) => {
    var toast = new Toast(document.querySelector(".toast"));
    toast.dispose();
    document.getElementById("toast-body").innerHTML = e.message;
    toast = new Toast(document.querySelector(".toast"));
    toast.show();
  });
}
