import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return [
      "goal",
      "checkbox",
      "otherGoal",
      "otherGoalLabel",
      "checkmark",
      "submitDiv"
    ];
  }

  goalTargetConnected() {
    this.goalTargets.forEach((goal) => {
      const checkbox = goal.querySelector("input");
      const checkmark = goal.querySelector(".fa-check");
      if (checkbox.checked) {
        goal.classList.add("outline-selected");
        goal.classList.remove("outline-unselected");
        checkmark.classList.remove("hide");
      }
    });
  }

  otherGoalTargetConnected() {
    this.disableOtherGoal();
  }

  goalClicked(e) {
    if (
      e.target.classList[1].includes("outline-unselected") &&
      this.checkGoalsSelected() < 1
    ) {
      e.target.classList.add("outline-selected");
      e.target.classList.remove("outline-unselected");
      this.checkboxTargets[e.target.id].checked = true;
      this.checkmarkTargets[e.target.id].classList.remove("hide");
      this.disableOtherGoal();
    } else {
      e.target.classList.remove("outline-selected");
      e.target.classList.add("outline-unselected");
      this.checkboxTargets[e.target.id].checked = false;
      this.checkmarkTargets[e.target.id].classList.add("hide");
      this.disableOtherGoal();
    }
    this.toggleSubmitDiv();
  }

  checkGoalsSelected() {
    let count = 0;
    this.checkboxTargets.forEach((box) => {
      if (box.checked) {
        count++;
      }
    });
    if (this.otherGoalTarget.value !== "") {
      count++;
    }
    return count;
  }

  disableOtherGoal() {
    if (this.checkGoalsSelected() < 1 || this.otherGoalTarget.value !== "") {
      if (
        [...this.otherGoalLabelTarget.classList].includes(
          "mdc-text-field--disabled"
        )
      ) {
        this.otherGoalLabelTarget.classList.remove("mdc-text-field--disabled");
      }
      this.otherGoalTarget.disabled = false;
    } else {
      this.otherGoalTarget.disabled = true;
    }
  }

  toggleSubmitDiv() {
    if (this.checkGoalsSelected() === 1) {
      this.submitDivTarget.classList.remove("hide");
    } else {
      this.submitDivTarget.classList.add("hide");
    }
  }
}
